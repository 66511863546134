<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      Buscar Negocio
      <template v-slot:actions>
        <v-btn class="mr-5" @click.native.stop="reloadp++" icon small>
          <v-icon color="primary">mdi-reload</v-icon>
        </v-btn>
        <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content :key="reloadp">
      <v-card>
        <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
          <v-tabs-slider color="red"></v-tabs-slider>

          <v-tab href="#tab-1">
            Buscar Negocio
            <v-icon>mdi-account-search</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <v-card flat>
              <v-card-text class="pa-1">
                <v-card flat>
                  <GeneralFilter
                    class="background border-box"
                    :search="{}"
                    :filterEndpoint="$API.business.businessListFiltered"
                    @emptyFilter="getBusinessList"
                    @filtered="filterHandler"
                  >
                    <DxDataGrid class="isScrolledByGeneralFilter"
                      :data-source="businessList"
                      key-expr="ID_Business"
                      :show-row-lines="showRowLines"
                      :show-borders="showBorders"
                      :row-alternation-enabled="rowAlternationEnabled"
                    >
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="10" />
                      <DxColumn
                        :width="80"
                        cell-template="show-template"
                        caption=""
                      ></DxColumn>
                      <!--DxColumn
                        data-field="ID_Business"
                        caption="ID"
                      ></DxColumn-->
                      <DxColumn
                        data-field="BusinessName"
                        caption="Nombre "
                      ></DxColumn>
                      <DxColumn data-field="BusinessPhone" caption="Telefono" />
                      <DxColumn
                        data-field="BusinessEmail"
                        caption="Correo"
                      ></DxColumn>

                      <template #show-template="{ data }">
                        <div>
                          <a
                            href="javascript:void(0)"
                            @click="businessSelected(data)"
                          >
                            <v-icon small class="mr-2"> mdi-eye </v-icon>
                          </a>
                          <a
                            v-if="type_user == 'ROLE_ROOT'"
                            href="javascript:void(0)"
                            @click.prevent="
                              deleteBusiness(data.data.ID_Business)
                            "
                          >
                            <v-icon color="error" small>mdi-delete</v-icon>
                          </a>
                        </div>
                      </template>
                    </DxDataGrid>
                  </GeneralFilter>
                </v-card>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
  <!--
    <add-business :key="businessToEdit.ID_Business" @reloadData="reloadData"
                  :business="businessToEdit" v-if="businessToEdit"></add-business>-->
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxPaging,
} from "devextreme-vue/data-grid";
import { mapGetters } from "vuex";
import GeneralFilter from "../../../../components/GeneralFilter.vue";
// import AddBusiness from "./AddBusiness";

export default {
  name: "BusinessList",
  components: { DxDataGrid, DxColumn, DxScrolling, DxPaging, GeneralFilter },
  props: ["win"],
  data() {
    return {
      search: "",
      tab: null,
      businessListData: [],
      defaultItem: {
        businessName: "",
        email: "",
      },
      businessList: [],
      businessToEdit: null,
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      type_user: null,
      business_user: null,
      reloadp: 0,
      unfilteredBusinessList: [],
    };
  },
  computed: {
    ...mapGetters(["reloadData"]),
  },
  watch: {
    reloadData: {
      immediate: true,
      handler: "reloadDataHandler",
    },
  },
  created() {
    this.getBusinessList();
  },

  methods: {
    async reloadDataHandler() {
      if (this.reloadData == "business") {
        await this.getBusinessList();
        this.$store.dispatch("reloadData", null);
      }
    },

    getBusinessList() {
      this.type_user = JSON.parse(localStorage.getItem("user")).roles[0];
      this.business_user = JSON.parse(localStorage.getItem("user")).business;
      this.$API.business.businessListHistory().then((response) => {
        this.unfilteredBusinessList = response; // Store unfiltered data
        this.filterHandler(this.unfilteredBusinessList); // Apply filtering
      });
    },

    filterHandler(response) {
      this.businessList = [];
      if (this.business_user != "none" && this.type_user == "ROLE_ADMIN") {
        for (var i = 0; i < response.length; i++) {
          if (response[i]["BusinessName"] == this.business_user) {
            this.businessList.push(response[i]);
          }
        }
      } else {
        this.businessList = response;
      }
    },

    deleteBusiness(id) {
      this.$API.business.deleteBusiness(id).then((response) => {
        this.$toasted.success(response.message);
        this.getBusinessList();
      });
    },
    
    businessSelected(data) {
      this.$store.dispatch("addWindow", {
        name: "business",
        component: "Business",
        unique: false,
        meta: { business: data.data },
      });
    },
  },
};
</script>

<style scoped>
.border-box {
  border-radius: 15px !important;
}
.background {
  background: white !important;
  color: white;
}
</style>
